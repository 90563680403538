<template>
  <section class="special-services-section" :class="{ 'ja' : $i18n.locale == 'ja' }">
    <div class="inner-with">
      <h2 class="main-title">{{ $t("youtubeLong.services.title") }} <br>{{ $t("youtubeLong.services.title2") }}</h2>
      <p class="text-font subtitle">{{ $t("youtubeLong.services.description") }}</p>

      <div class="row">
        <div class="text">
          <h3>
            {{ $t("youtubeLong.services.text1.title") }}
            <span>{{ $t("youtubeLong.services.text1.subTitle") }}</span>
          </h3>
          <p>{{ $t("youtubeLong.services.text1.text1") }}</p>
          <p>{{ $t("youtubeLong.services.text1.text2") }}</p>
        </div>
        <div class="img img-right">
           <img src="/images/youtube-promo-long/try1-min.png" :alt="$t('youtubeLong.offering.titleText')">
        </div>
      </div>

      <!--<div class="row">
        <div class="img">
           <img src="/images/youtube-promo-long/try2-min.png" :alt="$t('youtubeLong.offering.titleText')">
        </div>
        <div class="text">
          <h3 class="middle-title">
            {{ $t("youtubeLong.services.text2.title") }}
            <span>{{ $t("youtubeLong.services.text2.subTitle") }}</span>
          </h3>
          <p>{{ $t("youtubeLong.services.text2.text") }}</p>
          <ul class="bulets">
            <li>{{ $t("youtubeLong.services.text2.option1") }}</li>
            <li>{{ $t("youtubeLong.services.text2.option2") }}</li>
          </ul>
        </div>
      </div>-->

      <!-- <div class="row">
        <div class="img">
           <img src="/images/youtube-promo-long/try3-min.png" :alt="$t('youtubeLong.offering.titleText')">
        </div>
        <div class="text">
          <h3>
            {{ $t("youtubeLong.services.text3.title") }}
            <span>{{ $t("youtubeLong.services.text3.subTitle") }}</span>
          </h3>
          <p>{{ $t("youtubeLong.services.text3.text1") }}</p>
          <p>{{ $t("youtubeLong.services.text3.text2") }}</p>
        </div>
      </div> -->
    </div>
    <!-- <separator :bottomColor="'rgb(18, 19, 19)'" :topColor="'#F5F6FC'" :invertDegris="-1" :shapeClass="'shaped2'"/> -->
  </section>
</template>
<script>
// import separator from '../../components/youtube-lp/separator';
export default {
  components: {
    // separator
  }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import "../../scss/youtube-lp";


.special-services-section {
  position: relative;
  background: $white;
  padding-top: 15vh;
  overflow: hidden;
  h2 {
    padding-bottom: 2vh;
  }

  p {
    padding-bottom: 20vh;
    font-family: $family-light;
    @extend .text-font;
  }
}

.subtitle {
  position: relative;
  padding: 4vh 10vw;
  text-align: center;
  margin-top: 1vh;

  &::after {
        position: absolute;
        content: "";
        background-color: red;
        left: 50%;
        margin-left: -4rem;
        top: 0;
        display: block;
        height: 0.125rem;
        width: 8rem;
    }
}

.row {
  position: relative;
  padding: 0 0 10vh;
  display: flex;
  z-index: 1;

  h3 {
    padding-bottom: 2vh;
    position: relative;
    @extend .small-title;
    font-weight: 600;
    span {
      position: absolute;
      text-transform: uppercase;
      font-size: 15px;
      color: $text-grey;
      left: 0;
      top: -35px;
      display: block;
      &::after {
        content: "";
        background-color: red;
        display: block;
        height: 0.125rem;
        margin-top: 0.3rem;
        width: 3.75rem;
      }
    }
  }

  p {
    padding-bottom: 1.5vh;
    @extend .text-font;
  }
}

.text{
  width: 60%;

}
.img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  top: -4vh;
  width: 40%;
  img {
    width: 80%;
    border-radius: 4px;
    -webkit-box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
    box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
  }
}

.img-right {
   justify-content: flex-end;
}

@media (max-width: 1024px) {
  .special-services-section {
    padding-top: 5vh;
    p {
      padding-bottom: 10vh;
    }
  }

  .row p {
    padding-bottom: 1vh;
  }

  .img {
    top:0vh;
  }
}

@media (max-width: 576px) {

  .special-services-section {
    height: 1200px;
  }

  .row {
    flex-direction: column;
  }

  .row:nth-child(2n) {
    flex-direction: column-reverse;
  }
  
  .img {
    top:0;
    width: 100%;
    justify-content: center;
  }

  .text {
    padding-bottom: 5vh;
    padding-top: 5vh;
    width: 100%;
    @include font-size(16px);
  }

  .subtitle {
    padding-left: 0;
    padding-right: 0;
    @include font-size(16px);
  }

  .row h3 span {
    top: -40px;
  }
  
  .row h3 span::after {
    margin-top: 8px;
  }

  .ja .subtitle {
    @include font-size(16px);
  }

  .ja .text p{
    @include font-size(16px);
  }
}

</style>
