<template>
    <section class="intro-3-section" :class="{ 'ja' : $i18n.locale == 'ja' }"> 
        <div class="inner-with">
            <h2 class="client-params-title main-title">{{ $t('youtubeLong.intro3.title') }}</h2>
            <ul class="client-params">
                <li>
                    <span class="number">1</span>
                    <span class="text">{{ $t('youtubeLong.intro3.items.item1') }}</span>
                </li>
                <li>
                    <span class="number">2</span>
                    <span class="text">{{ $t('youtubeLong.intro3.items.item2') }}</span>
                </li>
                <li>
                    <span class="number">3</span>
                    <span class="text">{{ $t('youtubeLong.intro3.items.item3') }}</span>
                </li>
                <li>
                    <span class="number">4</span>
                    <span class="text">{{ $t('youtubeLong.intro3.items.item4') }}</span>
                </li>
                <li>
                    <span class="number">5</span>
                    <span class="text">{{ $t('youtubeLong.intro3.items.item5') }}</span>
                </li>
                <li>
                    <span class="number">6</span>
                    <span class="text">{{ $t('youtubeLong.intro3.items.item6') }}</span>
                </li>
            </ul>
        </div>
        <div class="slider-section">
            <div v-if="false"  class="slider-content inner-with">
                <div class="slider-content-inner">
                    <h3>{{ $t('youtubeLong.intro3.sliderTitle') }}</h3>
                    <p>{{ $t('youtubeLong.intro3.slederText') }}</p>
                    <a class="dnd-btn dnd-btn-w" href="#" v-scroll-to="'#packages'">{{ $t('youtubeLong.intro3.button') }}</a>
                </div>
            </div>
            <slider class="slider"/>
        </div>
    </section>
</template>
<script>
import slider from '../../components/youtube-lp/infinity-show-testimonials';
export default {
    data() {
        return {
            isPaused: false
        }
    },
    components: {
        slider
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import '../../scss/youtube-lp';

.intro-3-section {
    background: $white;
    position: relative;
    z-index: 2;
    padding: 30vh 0 5vh;
}

.client-params-title {
    z-index: 1;
    text-align: center;
}

.client-params {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 5vw;
    padding: 5vw 2vw;
    z-index: 1;

    li {
        width: 16.6%;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
    }
}

.number {
    display: block;
    text-align: center;
    color: #fff;
    background: url(/images/youtube-promo-long/num_bg.png) no-repeat top center;
    background-size: 31px 36px;
    width: 31px;
    line-height: 34px;
    height: 36px;
    margin-bottom: 15px;
}

.text {
    text-align: center;
    font-family: $family-light;
    height: 72px;
    @extend .small-text-font;
}

.slider-section {
    position: relative;
}

.slider-content {
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    left:0;
    z-index: 1;
    display: flex;
    align-items: center;
}

.slider-content-inner {
    margin-top: 5%;
    padding: 2% 30px;
    background-color: #fff;
    width: 50%;
    border-radius: 5px;

    h3 {
        position: relative;
        padding-bottom:20px;
        margin-bottom: 20px;

        @extend .small-title;

        &::after {
            content: '';
            position: absolute;
            bottom:0;
            left: 0;
            height: 2px;
            width: 20%;
            @extend .gradient-bg;
        }
    }

    p {
        @extend .small-text-font;
        padding-bottom: 20px;
    }

    a {
       float: right;
    }
}

.ja .slider-content-inner h3 {
    font-weight: 600;
}

@media (max-width: 1024px) {
    .intro-3-section {
        padding: 20vh 0 5vh;
    }
}

@media (max-width: 768px) {
    .slider-section {
        padding-top: 10vh;
    }

    .slider-content-inner {
        margin-top: 15vh;
        width: 65%;
        padding:30px;
        
        h3 {
            padding-bottom:10px;
            margin-bottom:10px;
        }

        p {
            padding-bottom: 10px;
        }
    }

    .ja .slider-content-inner h3 {
        font-size: 4.9vw;
        font-weight: 600;
    }

    .ja .slider-content-inner a {
        float: none;
        font-size: 4.6vw;
    }
}

@media (max-width: 576px) {
    .intro-3-section {
        padding: 20vh 0 5vh;
        height: 2400px;
    }
    .slider-section {
        padding-top: 5vh;
    }

    .slider-content-inner {
        width: 100%;
        margin-top: 25vh;

        h3::after {
            width: 50%;
        }
    }

    .client-params {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 5vw;
        padding: 5vw 2vw;
        z-index: 1;

        li {
            width: 100%;
            height: auto;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 10px 0;

            .text {
                text-align: left;
                width: 80%;
                height: auto;
            }

            .number {
                width: 20%;
                align-items:center;
                justify-content: flex-start;
                margin-bottom: 0;
                background: none;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    margin-left: -12.5px;
                    margin-top: -2px;
                    background: url(/images/youtube-promo-long/num_bg.png) no-repeat top center;
                    background-size: 31px 36px;
                    width: 31px;
                    line-height: 34px;
                    height: 36px;
                    z-index: -1;
                    @include rotate(-90deg);
                }
            }
        }
    }
}

</style>