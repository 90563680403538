<template>
  <header class="header" :class="{ 'ja' : $i18n.locale == 'ja' }">
    <div class="header-inner inner-with">
      <div class="text-container">
        <h1>
          <span>{{ $t('youtubeLong.header.title.row1') }}</span>
          <span>{{ $t('youtubeLong.header.title.row2') }}</span>
          <span>{{ $t('youtubeLong.header.title.row3') }}</span>
        </h1>
        <div class="btn-container">
          <a class="dnd-btn" href="#packages" v-scroll-to="'#packages'">{{
            $t("youtubeLong.ctaBtnText")
          }}</a>
        </div>
        <h2>
          <span class="small-title">{{ $t('youtubeLong.header.subTitle.row1') }}</span>
          <span class="small-title">{{ $t('youtubeLong.header.subTitle.row2') }}</span>
        </h2>
      </div>
      <teaser class="teaser"/>
      <div class="scroll-down-container">
        <a href="#" v-scroll-to="'#intro'">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
    </div>
    <div class="header-bg">
      <div class="shape-1"></div>
    </div>
     <separator class="separator-bottom" :circle="true" :circleRight="true" :isBottom="true" :topColor="'#1a1b1b'" :bottomColor="'#F5F6FC'" />
  </header>
</template>
<script>
import teaser from '../../components/youtube-lp/youtube-teaser';
import separator from '../../components/youtube-lp/separator';

export default {
    components: {
      teaser, separator
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import '../../scss/youtube-lp';

.header {
  position: relative;
  width: 100%;
  height: auto;
  height: 120vh;
  overflow: hidden;
}

.header-inner {
  position: relative;
  height: inherit;
  z-index: 1;
  display: flex;
  align-items: center;
}



.text-container {
    width:60%;
}

.text-container h1{
    color: #fff;

    span {
        display: block;
        font-size: 4.5vw;
        text-transform: uppercase;
        font-family: $family-bold;
    }
}

.ja .text-container h1 {
    color: #fff;

    span {
        display: block;
        font-size: 3.5vw;
        text-transform: none;
        font-family: $family-bold;
        font-weight: 700;
    }
}

.text-container h2 {
    color: #fff;
    padding-top: 50px;
    padding-top: 5vh;

    span {
        display: block;
        font-size: 2vw;
        font-family: $family-light;
    }
}

.ja .text-container h2 {
  span {
    display: block;
    font-size: 1.7vw;
    font-family: $family-light;
  }
}

.teaser {
    width: 40%;
}

.header-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $black-dark;
  z-index: 0;
  overflow: hidden;
}

.scroll-down-container {
  position: absolute;
  top: 75%;
  left: 50%;
  a {
    padding-top: 80px;
  }

  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    @include rotate(-45deg);
    -webkit-animation: sdb07 2s infinite;
    animation: sdb07 2s infinite;
    opacity: 0;
    box-sizing: border-box;

    &:nth-of-type(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }

    &:nth-of-type(2) {
      top: 16px;
      -webkit-animation-delay: .15s;
      animation-delay: .15s;
    }

    &:nth-of-type(3) {
      top: 32px;
      -webkit-animation-delay: .3s;
      animation-delay: .3s;
    } 
  }
}

@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.separator-bottom {
    position: absolute;
    bottom:0;
    left: 0;
    right:0;
}

.btn-container {
  display: none;
}

@media (max-width: 1024px) {
  .teaser {
    display: none;
  }

  .text-container {
    width:100%;
  }

  .text-container h1 span {
    font-size: 8.5vw;
  }

  .ja .text-container h1 {
    span {
      font-size: 7.5vw;
    }
  }


  .text-container h2 {
    color: #fff;
    padding-top: 30px;
    padding-top: 5vh;

    span {
        display: block;
        font-size: 3vw;
        font-family: $family-light;
    }
  }

  .ja .text-container h2 span {
    font-size: 4vw;
  }

  .scroll-down-container {
    top:81%;
  }
}

@media (max-width: 576px) {
  .header {
    position: relative;
    width: 100%;
    height: auto;
    height: 113vh;
    height: 900px;
    overflow: hidden;
  }

  .header-bg .shape-1 {
    top:-80vh;
  }

  .text-container h1 span {
    font-size: 9.4vw;
  }

  .ja .text-container h1 {
    span {
      font-size: 8vw;
    }
  }

  .text-container h2 span {
    font-size: 6vw;
  }

  .ja .text-container h2 span {
    font-size: 5.5vw;
  }

  .scroll-down-container {
    display: none;
  }

  .btn-container {
    display: block;
    padding: 5vh 10% 0;

    a {
      display: block;
      font-size: 7vw;
      font-family: $family-bold;
    }
  }
}
</style>
