<template>
  <section class="testimonials-wrapper" :class="{ 'ja' : $i18n.locale == 'ja' }">
    <div class="inner-with">
      <h2 v-if="false" class="main-title">{{ $t("youtubeLong.testimonials.title") }}</h2>
      <div v-if="false" class="testimonials-carousel">
        <div class="view">
          <Carousel
            :perPage="1"
            :navigateTo="slideIndex"
            :paginationEnabled="false"
            v-on:page-change="onSlideChange"
          >
            <Slide v-for="(val, i) in items" v-bind:key="i">
              <div
                class="slide"
                :style="{ 'background-image': 'url(' + val.img + ')' }"
              >
                <div class="slide-inner" :class="val.class">
                  <p class="text">{{ $t(val.text) }}</p>
                  <div class="slide-img-container">
                    <img class="slide-img" :src="val.logoImg" alt="">
                  </div>
                  <p class="position">{{ $t(val.position) }}</p>
                </div>
              </div>
            </Slide>
          </Carousel>
        </div>
        <div v-if="false" class="pagination">
          <div
            v-for="(val, i) in items"
            v-bind:key="i"
            class="point-dash"
            :class="{ active: slideIndex == i }"
            @click="slideIndex = i"
          >
          <div class="dash"></div>
          </div>
        </div>
      </div>
      <div v-if="false" class="logo-row">
        <div class="logo-cell animation-third" :class="{ 'slider-active': slideIndex == 0 }" @click="slideIndex = 0">
          <img
            src="images/youtube-promo-lp/partners/Universal_Logo_white.svg"
            alt="Universal Music Group"
          />
        </div>

        <div class="logo-cell animation-second" :class="{ 'slider-active': slideIndex == 1 }" @click="slideIndex = 1">
          <img
            src="images/youtube-promo-lp/partners/Shiseido_Logo_white.svg"
            alt="SHISEIDO"
          />
        </div>

        <div class="logo-cell animation-second" :class="{ 'slider-active': slideIndex == 2 }" @click="slideIndex = 2">
          <img
            src="images/youtube-promo-lp/partners/Pepsi_Logo_white.svg"
            alt="Pepsi Logo"
          />
        </div>

        <div class="logo-cell animation-third" :class="{ 'slider-active': slideIndex == 3 }" @click="slideIndex = 3">
          <img
            src="images/youtube-promo-lp/partners/Warner_Logo_white.svg"
            alt="Warner Logo"
          />
        </div>
      </div>
      <div class="consultant-warapper">
        <div class="consultant-col-img">
          <img src="/images/youtube-promo-long/advisor-min.png" :alt="$t('youtubeLong.testimonials.consultant.name')">
          <p class="consultant-name">{{ $t("youtubeLong.testimonials.consultant.name") }}</p>
          <p class="consultant-position">
            <span>{{ $t("youtubeLong.testimonials.consultant.position") }}</span>
            <span>{{ $t("youtubeLong.testimonials.consultant.position2") }}</span>
          </p>
        </div>
        <div class="consultant-col-text">
          <h3 class="middle-title">{{ $t("youtubeLong.testimonials.consultant.title") }}</h3>
          <p class="text-font">{{ $t("youtubeLong.testimonials.consultant.text") }}</p>
          <a class="dnd-btn dnd-btn-w" href="#" v-scroll-to="'#packages'">{{ $t("youtubeLong.testimonials.consultant.buttonText") }}</a>
        </div>
      </div>
    </div>
    <separator class="section-separator" :topColor="'#1a1b1b'" :bottomColor="'#F5F6FC'" :shapeClass="'shaped'"/>
  </section>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import separator from '../../components/youtube-lp/separator';

export default {
  components: {
    Carousel,
    Slide,
    separator
  },
  methods: {
    onSlideChange(val) {
      this.slideIndex = val;
    }
  },
  data() {
    return {
      slideIndex: 0,
      items: [
        {
          img: "/images/youtube-promo-long/testimonials/slack.jpg",
          logoImg: "/images/youtube-promo-lp/partners/Universal_Logo_white.svg",
          class: "slide2",
          text: "youtubeLong.testimonials.carousel.item1.text",
          position: "youtubeLong.testimonials.carousel.item1.position",
          industry: this.$t("youtubeLong.testimonials.carousel.item1.industry"),
        },
        {
          img: "/images/youtube-promo-long/testimonials/shiedo-testimonial-bg.jpg",
          logoImg: "/images/youtube-promo-lp/partners/Shiseido_Logo_white.svg",
          class: "slide1",
          text: "youtubeLong.testimonials.carousel.item2.text",
          position: "youtubeLong.testimonials.carousel.item2.position",
          industry: this.$t("youtubeLong.testimonials.carousel.item2.industry"),
        },
        {
          img: "/images/youtube-promo-long/testimonials/pepsi.jpg",
          logoImg: "/images/youtube-promo-lp/partners/Pepsi_Logo_white.svg",
          class: "slide4",
          text: "youtubeLong.testimonials.carousel.item3.text",
          position: "youtubeLong.testimonials.carousel.item3.position",
          industry: this.$t("youtubeLong.testimonials.carousel.item3.industry"),
        },
        {
          img: "/images/youtube-promo-long/testimonials/flor.jpg",
          logoImg: "/images/youtube-promo-lp/partners/Warner_Logo_white.svg",
          class: "slide3",
          text: "youtubeLong.testimonials.carousel.item4.text",
          position: "youtubeLong.testimonials.carousel.item4.position",
          industry: this.$t("youtubeLong.testimonials.carousel.item4.industry"),
        }
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import "../../scss/youtube-lp";

.testimonials-wrapper {
  padding: 15vh 0 20vh;
  background: #1a1b1b;
  position: relative;

  h2 {
    padding-bottom: 5vh;
    color: $white;
  }

  .inner-with {
    position: relative;
  }
}

.section-separator {
  position: absolute !important;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
}

.testimonials-carousel {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -24px;
}

.point-dash {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active .dash{
    @include opacity(1);
  }
}

.dash {
  height: 2px;
  background: #fff;
  display: block;
  margin: 0 2px;
  width: 24px;
  @include opacity(0.4);
}

.slide {
  position: relative;
  height: 20vw;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
  border-radius: 10px;
}

.slide-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 48px 0 48px 48px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.text {
  width: 60%;
  color: $white;
  @extend .small-text-font;
  font-size: 1.1vw;
}

.slide-img {
  display: none;
}

.position {
  font-family: $family-bold;
  font-weight: 600;
  padding-top: 30px;
  @include font-size(18px);
  color: $white;
}

.industry {
  font-family: $family-bold;
  @include font-size(18px);
  color: $white;
}

.slide1 {
  background: rgb(190, 65, 163);
  background: linear-gradient(
    90deg,
    rgba(190, 65, 163, 1) 54%,
    rgba(190, 65, 163, 0.6629026610644257) 100%
  );
}

.slide2 {
  background: rgb(97, 31, 105);
  background: linear-gradient(
    90deg,
    rgba(97, 31, 105, 1) 56%,
    rgba(97, 31, 105, 0.31556372549019607) 100%
  );
}

.slide3 {
  background: rgb(97, 31, 105);
  background: linear-gradient(
    90deg,
    rgba(222, 100, 94, 1) 56%,
    rgba(246, 189, 37, 0.31556372549019607) 100%
  );
}

.slide4 {
  background: rgb(97, 31, 105);
  background: linear-gradient(
    90deg,
    rgba(144, 51, 165, 1) 56%,
    rgba(73, 35, 216, 0.31556372549019607) 100%
  );
}

$fadeDuration: .7s;

.logo-row {
  padding-top: 2vh;
  padding-bottom: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    .logo-cell img {
      @include opacity(1);
      @include translateY(0);
    }
  }
}

.logo-cell {
  width: 220px;
  padding: 0 30px;
  text-align: center;
  overflow: hidden;

  &.animation {
    &-first img {
      @include transition(all ease 1s);
      @include transition-delay($fadeDuration / 6);
    }
    &-second img {
      @include transition(all ease 1s);
      @include transition-delay(($fadeDuration / 6) * 2);
    }
    &-third img {
      @include transition(all ease 1s);
      @include transition-delay(($fadeDuration / 6) * 3);
    }
  }

  img {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    @include opacity(.5);
    @include translateY(0);
    will-change: opacity;
  }

  .mitsubishi-logo {
    height: 80px;
    width: auto;
  }

  &.slider-active img {
    @include opacity(1);
  }
}

.consultant-warapper {
  position: absolute;
  z-index: 3;
  bottom: -30vh;
  width: 100%;
  display: flex;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
  box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
  padding: 40px 40px;
}

.consultant-col-img {
  width: 20%;
  padding-right: 20px;

  img {
    width: 70%;
    margin-bottom: 10px;
  }

  p {
    font-family: $family-bold;
    @include font-size(19px);
    padding: 0 0 0;
  }

  .consultant-position {
    @include font-size(19px);
    span {
      font-family: $family-light;
      display: block;
    }
  }
}

.ja .consultant-col-img .consultant-position {
  font-size: 1vw;
}

.consultant-col-text {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
  width: 80%;

  a {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  p {
    padding-bottom: 8%;
  }
}

.consultant-col-text .middle-title {
  font-weight: 600;
}

@media (max-width: 1024px) {
  .testimonials-wrapper{
    padding:0vh 0 350px;
  }

  .consultant-warapper {
      bottom: -450px;
  }

  .consultant-col-img {
    width: 35%;
  }

  .consultant-col-text p {
    padding-bottom: 15%;
  }

  .slide {
    height: 40vw;
  }

  .text {
    width: 80%;
    font-size: 2.1vw;
  }

  .ja .consultant-col-img .consultant-position {
    font-size: 2.1vw;
  }
}

@media (max-width: 576px) {

  .testimonials-wrapper{
    padding: 10vh 0 30vh;
    height: 400px;
  }

  .slide {
    height: 650px;
    background-size: auto 63%;
    background-position: bottom;
  }

  .text {
    width: 100%;
    @include font-size(18px);
    text-align: justify;
  }

  .slide-inner {
    padding:20px;
    justify-content: flex-start;
  }

  .logo-row {
    display: none;
  }

  .slide-img-container {
    position: absolute;
    bottom: 12vh;
    left: 0.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;
  }

  .slide-img {
    display: block;
    width: 160px;
  }

  .consultant-warapper {
      bottom: -404px;
      padding: 20px;
  }

  .consultant-col-img {
    position: absolute;
    left:5vw;
    bottom: 10vh;
    width: 100%;

    img { 
      width: 70px;
      float: left;
      margin-right: 10px;
    }
  }

  .consultant-col-text {
    width: 100%;
    padding-bottom: 19vh;

    p {
      font-size: 4vw;
      padding: 20px 0;
    }

    a {
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 95%;
      font-size: 4.5vw;
    }
  }

  .slide1 {
    background: rgb(190, 65, 163);
    background: linear-gradient(
      180deg,
      rgba(190, 65, 163, 1) 54%,
      rgba(190, 65, 163, 0.6629026610644257) 100%
    );
  }

  .slide2 {
    background: rgb(97, 31, 105);
    background: linear-gradient(
      180deg,
      rgba(97, 31, 105, 1) 56%,
      rgba(97, 31, 105, 0.31556372549019607) 100%
    );
  }

  .slide3 {
    background: rgb(97, 31, 105);
    background: linear-gradient(
      180deg,
      rgba(222, 100, 94, 1) 56%,
      rgba(246, 189, 37, 0.31556372549019607) 100%
    );
  }

  .slide4 {
    background: rgb(97, 31, 105);
    background: linear-gradient(
      180deg,
      rgba(144, 51, 165, 1) 56%,
      rgba(73, 35, 216, 0.31556372549019607) 100%
    );
  }

  .ja .consultant-col-img .consultant-position {
    font-size: 19px;
  }

  .ja .position {
    display: none;
  }
}
</style>
