<template>
    <section id="packages" class="packages-wrapper" :class="{ 'ja' : $i18n.locale == 'ja' }">
        <div class="inner-with">
            <h2 class="main-title">
                <span>{{ $t("youtubeLong.packages.title") }}</span>
                <span class="title-underline">{{ $t("youtubeLong.packages.subTitle") }}</span>
            </h2>
            <p>
                <span v-html="$t('youtubeLong.packages.text1', { price: packagePrice1 })"></span>
                {{ $t("youtubeLong.packages.text3", { price: packagePrice1 }) }}
            </p>

            <div class="packages">
                <div class="package" :class="{ 'discount': packageDiscount1, 'safary': isIos }">
                    <img class="thumb-invest-img" src="/images/youtube-promo-long/thumb-invest.png" alt="thumb-invest">
                    <h4>{{ packageTitle1 }}</h4>
                    <p class="discount-label" v-if="packageDiscount1">{{ +packageDiscount1 }}</p>
                    <p v-if="packageDiscount1" class="discount-price-label"><span class="discount-package-price-label">${{+packagePrice1}}</span>{{packageDiscountPrecent1}}% OFF!</p>
                    <p class="price-label" v-if="packagePrice1">{{packagePrice1}}</p>
                    <p class="price-label" v-if="!packagePrice1">---</p>
                    <p>
                        <span v-if="$i18n.locale == 'en'">{{ packageDescription1 }}</span>
                        <span v-if="$i18n.locale == 'ja'">{{ packageDescriptionJa1 }}</span>
                        <span v-if="$i18n.locale != 'ja'">{{ $t("youtubeLong.packages.offering1.text2") }}</span>
                    </p>
                    <a @click="checkout('minimum')" class="dnd-btn dnd-btn-w">{{ $t("youtubeLong.packages.offering1.link") }}</a>
                    <div class="separatop"></div>
                     <ul class="package-params">
                        <li>{{ packageViewsAmount1 }} {{ $t("youtubeLong.packages.offering1.list1.option") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering1.list2.option1") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering1.list2.option2") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering1.list2.option3") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering1.list2.option4") }}</li>
                    </ul>
                </div>

                <div class="package black" :class="{ 'discount': packageDiscount2, 'safary': isIos }">
                    <img class="sailboat-img" src="/images/youtube-promo-long/sailboat.png" alt="sailboat">
                    <span class="label">
                        <span class="label-inner">
                            <span>{{ $t("youtubeLong.packages.offering2.label1") }}</span>
                            <span>{{ $t("youtubeLong.packages.offering2.label2") }}</span>
                        </span>
                    </span>
                    <h4>{{ packageTitle2 }}</h4>
                    <p class="discount-label" v-if="packageDiscount2">{{ packageDiscount2 }}</p>
                    <p class="price-label" v-if="packagePrice2">{{ packagePrice2 }}</p>
                    <p  v-if="packageDiscount2" class="discount-price-label"><span class="discount-package-price-label">${{packagePrice2}}</span>{{packageDiscountPrecent2}}% OFF!</p>
                    <p class="price-label" v-if="!packagePrice2">---</p>
                    <p>
                        <span v-if="$i18n.locale == 'en'">{{ packageDescription2 }}</span>
                        <span v-if="$i18n.locale == 'ja'">{{ packageDescriptionJa2 }}</span>
                        <span v-if="$i18n.locale != 'ja'">{{ $t("youtubeLong.packages.offering2.text2") }}</span>
                    </p>
                    <a @click="checkout('basic')" class="dnd-btn">{{ $t("youtubeLong.packages.offering2.link") }}</a>
                    <div class="separatop"></div>
                     <ul class="package-params">
                        <li>{{ packageViewsAmount2 }} {{ $t("youtubeLong.packages.offering2.list1.option") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering2.list2.option1") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering2.list2.option2") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering2.list2.option3") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering2.list2.option4") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering2.list2.option5") }}</li>
                    </ul>
                </div>

                <div class="package begge">
                    <img class="generation-img" src="/images/youtube-promo-long/generation.png" alt="generation">
                    <h4>{{ $t("youtubeLong.packages.offering3.title") }}</h4>
                    <p class="price-label">
                        <img src="/images/youtube-promo-long/victory-hand.png" alt="Victory hand">
                    </p>
                    <p>
                        <span>{{ $t("youtubeLong.packages.offering3.text") }}</span>
                    </p>
                    <router-link class="dnd-btn dnd-btn-w" to="/checkout-special">{{ $t("youtubeLong.packages.offering3.link") }}</router-link>
                    <div class="separatop"></div>
                     <ul class="package-params">
                        <li>{{ $t("youtubeLong.packages.offering3.list1.option") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering3.list2.option1") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering3.list2.option2") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering3.list2.option3") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering3.list2.option4") }}</li>
                        <li>{{ $t("youtubeLong.packages.offering3.list2.option5") }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: mapGetters([
        'isIos'
    ]),
    data() {
        return {
            packageSlug1: 'minimum',
            packageTitle1: null,
            packagePrice1: null,
            packageDescription1: null,
            packageDescriptionJa1: null,
            packageViewsAmount1: null,
            packageDiscount1: null,
            packageDiscountPrecent1: null,
            
            packageSlug2: 'basic',
            packageTitle2: null,
            packagePrice2: null,
            packageDescription2: null,
            packageDescriptionJa2: null,
            packageViewsAmount2: null,
            packageDiscount2: null,
            packageDiscountPrecent2: null
        }
    },
    methods: {
        checkout(packageSlug) {
            this.$cookies.set('shopping-cart', packageSlug);
            this.$router.push({ name: "checkout" });
        }
    },
    mounted() {
            
        this.$http({ url: 'packages/slug/' + this.packageSlug1, method: 'GET'})
            .then((resp) => { 
                    this.packageTitle1 = resp.data.data.title;
                    this.packagePrice1 = +resp.data.data.price;
                    this.packageDescription1 = resp.data.data.description;
                    this.packageDescriptionJa1 = resp.data.data.description_ja;
                    this.packageViewsAmount1 = parseInt(resp.data.data.views_amount).toLocaleString('ru-RU');
                    this.packageDiscount1 = Number(resp.data.data.discount_value);
                    this.packageDiscountPrecent1 = Number(resp.data.data.discount);
                    
                });

        this.$http({ url: 'packages/slug/' + this.packageSlug2, method: 'GET'})
            .then((resp) => { 
                    this.packageTitle2 = resp.data.data.title;
                    this.packagePrice2 = +resp.data.data.price;
                    this.packageDescription2 = resp.data.data.description;
                    this.packageDescriptionJa2 = resp.data.data.description_ja;
                    this.packageViewsAmount2 = parseInt(resp.data.data.views_amount).toLocaleString('ru-RU');
                    this.packageDiscount2 = Number(resp.data.data.discount_value);
                    this.packageDiscountPrecent2 = Number(resp.data.data.discount);
                });
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import '../../scss/youtube-lp';

.packages-wrapper {
    padding: 10vh 0 20vh;
    background: $white;

    h2 {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-family: $family-bold;
        line-height: 40px;
        @include font-size(30px);
        text-align: center;
        padding-bottom: 5vh;

        span:first-child {
            color: #939999;
            font-family: $family-light;
            font-weight: 500;
        }

        span:last-child {
            display: inline-block;
            padding-top: 6px;
            @include font-size(35px);
            font-size: 2.6vw;
        }

        span::after {
            display: block;
        }

        .title-underline::after {
           bottom: -2px;
        }
    }

    h3 {
        padding-bottom: 2vh;
        padding-bottom: 5vh;
    }

    p {
        font-family: $family-light;
        @extend .text-font;
        font-size: 1.7vw;
        text-align: center;
        padding: 25px 0 50px;

        span {
            font-family: $family-light;
            @extend .text-font;
        }
    }
}

.packages {
    padding: 20vh 0 10vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.package.discount {
    .price-label {
        display: none;
    }
    .discount-label {
        color: red;
    }
    
    .discount-price-label {
        position: relative;
        display: flex;
        color:rgb(239, 161, 55);
        top: -11px;
        font-family: $family-bold;
        @include font-size(28px);
        line-height: 30px;

        
        &::before {
            display: none;
        }

        .discount-package-price-label {
            position: relative;
            display: flex;
            align-items: flex-end;
            @include font-size(23px);
            font-family: sans-serif;
            font-weight: 600;
            color: #212529;
            margin-right: 6px;
            line-height: 28px;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 15px;
                height: 2px;
                width: 100%;
                background: #212529;
            }
        }
    }
}

.package {
    position: relative;
    height: 950px;
    background: #fff;
    width: 30%;
    padding: 90px 2% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    @include transition(all .2s ease);


    &.begge .price-label img {
        display: block;
        height: 90px;
        margin-bottom: 40px;
    }
    
    h4 {
        @extend .small-title;
        padding-bottom: 2vh;
    }

    p {
        span {
            display: block;
            text-align: center;
            @extend .text-font;
        }
    }

    .price-label,
    .discount-price-label,
    .discount-label {
        font-family: $family-bold;
        @include font-size(50px);
        padding: 0;

        &::before {
            content: '$';
            @include font-size(25px);
            position: relative;
            top: -15px;
        }
    }

    .discount-price-label {
        position: relative;
        &::before {
            content: '$';
            @include font-size(19px);
            position: relative;
            top: -1px;
            left: -8px;
        }
    }

    .price-label + p span {
        font-size: 20px;
        padding: 0;
    }



    a {
        margin: 0px 0 50px;
    }

    &.black {
        background: #2F2F2F;
        color: #fff;

        .dnd-btn::before {
            background: #2F2F2F;
        }

        &.discount .discount-price-label {
            span {
                color: #fff;
            }

            span:first-child {
                color: #fff;
                &::after {
                    background: #fff;
                }

            }
        }
    }


    &.begge {
        background: #F7EBE1;
         color: #6E7272;

        .dnd-btn::before {
            background: #F7EBE1;
        }

        .package-params li {
            color: #6E7272;
        }


        .price-label {
            img {
                display: block;
                height: 90px;
                margin-bottom: 40px;
            }
            &::before {
                display: none;
            }
        }

        
        .price-label + p {
            font-size: 20px;
            padding: 0 0 75px;
        }
    }

    &:hover {
        @include translateY(-20px);
    }

    @extend .shadow;
}

.ja .package .price-label + p span {
    font-weight: 700;
}

.package.discount.safary {
    .discount-price-label {
        .discount-package-price-label {
            top:-2px;
        }
    }
}

.package-params {
    padding-top: 20px;
    width: 100%;
    padding-left: 60px;

    li {
        position: relative;
        padding-bottom: 30px;
        @extend .small-text-font;

        &::before {
            content: '';
            position: absolute;
            left: -46px;
            top: -1px;
            height: 22px;
            width: 22px;
            background-image: url('/images/youtube-promo-long/check-icon.svg');
        }

        &:first-child {
            font-family: $family-bold;
            font-weight: 700;
        }
    }
}

.separatop {
    width: 95%;
    height: 1px;
    background: #ccc;
    margin-bottom: 30px;
}

.label {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    overflow: hidden;
    height: 120px;
    width: 150px;
    border-top-right-radius: 10px;
    .label-inner {
        position: absolute;
        top: -51px;
        right: -81px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        height: 110px;
        width: 170px;
        background:$gold;
        @include rotate(45deg);
        padding-bottom: 5px;

        span {
            line-height: 15px;
        }
    }
}

.ja .label .label-inner  {
    top: -39px;
    right: -72px;
    height: 102px;
}

.thumb-invest-img {
    position: absolute;
    top: -12%;
    width: 175px;
    margin-left: 50%;
    left:-20px;
    @include translateX(-50%);

    -webkit-box-reflect: below -36% linear-gradient(transparent, transparent, transparent, rgba(255, 255, 255, .7));
}

.sailboat-img {
    position: absolute;
    top: -15%;
    width: 188px;
}

.generation-img {
    position: absolute;
    width: 120px;
    top:-6%;
}


.packages-wrapper .warning-bottom-package-p {
    font-family: $family-light;
    @include font-size(19px);
    padding: 0;
    padding-bottom: 20px;
    text-align: left;

    a {
        font-weight: 700 !important; 
    }
}


@media (max-width: 1024px) {
    .packages-wrapper {
        padding-bottom: 5vh;

        h2 span:last-child {
            font-size: 4vw;        
        }

        h2 .title-underline::after {
            bottom: -4px;
        }

        p {
            padding: 20px 0 20px;
        }
    }

    .packages {
        padding: 10vh 0 5vh;
        display: flex;
        align-items: center;
        justify-content: space-around;

        a {
            margin: 0px 0 30px;
        }
    }

    .package {
        position: relative;
        height: 100%;
        background: #fff;
        width: 30%;
        padding-bottom: 5vh;
        margin-bottom: 10vh;
        height: 1000px;

        &:hover {
            @include translateY(0);
        }

        &.begge .price-label img {
            display: block;
            height: 100px;
            margin-bottom: 10px;
        }

        &.begge {
            
            .price-label + p {
                font-size: 20px;
                padding: 0 0 40px;
            }
        }
    }

    .package-params {
   
        li {
            font-size: 1.6vw;
        }
    }

    .ja .package-params {
        li {
            font-size: 1.2vw;
        }
    }

    .ja .package {
        height: 850px;
        margin-bottom: 3vh;
    }

    .packages-wrapper h2 + p {
        padding: 2vh 4vw 1vh;
        font-size: 2vw;
        span {
            font-size: 2vw;
        }
    }
}

@media (max-width: 768px) { 
    .packages {
        padding: 10vh 0 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

     .package {
        width: 50%;
        height: 800px;

        &.begge .price-label img {
            display: block;
            width: auto;
            height: 70px;
            margin: 20px 0 30px;
        }
     }
}


@media (max-width: 576px) {

    .packages-wrapper {
        max-height: 2780px;
    }

    .packages {
        padding: 10vh 0 5vh;
    
    }

    .package {
        height: 100%;
        width: 95%;
        padding-bottom: 5vh;
        margin-bottom: 10vh;
        padding-top: 5vh;

        h4 {
            padding-bottom: 0vh;
            padding-top: 2vh;
        }

        .package-params {
            padding-top: 2vh;
        }

        .package-params li {
            padding-bottom: 2vh;
            font-size: 4.3vw;
        }

        a {
            margin: 0px 0 3vh;
        }

        img {
            width: 120px;
        }

        .thumb-invest-img {
            left: -12px;
        }

        .generation-img {
            width: 90px;
        }

        .price-label,
        .discount-label,
        .discount-price-label {
            padding-bottom: 0;
        }
    }

    .separatop {
        width: 95%;
        height: 1px;
        background: #ccc;
        margin-bottom: 10px;
    }

    .packages-wrapper {
        padding: 5vh 0 10vh;
        
        h2 {
            
            span {
                display: block;
                font-size: 4.6vw;
            }

            span:first-child {
                color: #939999;
            }

            span:last-child {
                font-size: 5.4vw;
                padding-top: 0;
                padding-bottom: 20px;
            }

            span::after {
                display: block;
            }
        }
    }

    .ja .package {
        height: 100%;
        margin-bottom: 10vh;
    }

 

    .packages-wrapper .main-title + p, .packages-wrapper .main-title + p span {
        @include font-size(16px);
    }

    .packages-wrapper .main-title .title-underline::after {
        content: '';
        position: absolute;
        left:0;     
        bottom: 4px !important; 
        right:0;
        height: 15px;
        background: $gold;
        z-index: -1;
        border-radius: 5px;
        width: 100%;
    }

    .packages-wrapper .main-title {
        font-family: $family-light;
    }

    .packages-wrapper .main-title .title-underline {
        padding-bottom: 0;
        font-weight: 600;
    }
}

</style>