<template>
    <div class="separator" :class="shapeClass">
        <div class="separator-inner" :class="{ 'circled': circle, 'inverse': isBottom }">
            <div class="top" :style="{
                '-webkit-transform': 'rotate('+ degrees * invertDegris + 'deg)',
                '-moz-transform': 'rotate('+ degrees * invertDegris + 'deg)',
                '-ms-transform': 'rotate('+ degrees * invertDegris  + 'deg)',
                '-o-transform': 'rotate('+ degrees * invertDegris  + 'deg)',
                'transform': 'rotate('+ degrees * invertDegris + 'deg)',
                'background': topColor
            }"></div>
            <div class="bottom" :style="{
                '-webkit-transform': 'rotate('+ degrees * invertDegris + 'deg)',
                '-moz-transform': 'rotate('+ degrees * invertDegris  + 'deg)',
                '-ms-transform': 'rotate('+ degrees * invertDegris  + 'deg)',
                '-o-transform': 'rotate('+ degrees * invertDegris  + 'deg)',
                'transform': 'rotate('+ degrees * invertDegris  + 'deg)',
                'background': bottomColor
            }"></div>
            <div v-if="circle" :class="{'shape-right': circleRight, 'shape-left': circleLeft && !circleRight }"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            degrees:0
        }
    },
    props: {
        topColor: {
            default: "#f5f5f5",
            type: String
        },
        bottomColor: {
            default: "rgb(18, 19, 19)",
            type: String
        },
        invertDegris: {
            default: 1,
            type: Number
        },
        shapeClass: {
            default: "",
            type: String
        },
        circle: {
            default: false,
            type: Boolean
        },
        circleLeft: {
            default: true,
            type: Boolean
        },
        circleRight: {
            default: false,
            type: Boolean
        },
        isBottom: {
            default: false,
            type: Boolean
        }
    },
    created() {
        window.addEventListener("resize", this.onDocumentChange)
    },
    destroyed() {
        window.removeEventListener("resize", this.onDocumentChange)
    },
    methods: {
        onDocumentChange() {
            const separator = document.querySelector('.separator-inner');
            this.degrees =  180 / Math.PI * Math.atan2(separator.offsetHeight, separator.offsetWidth);
        }, 
    },
    mounted() {
        this.onDocumentChange();
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import '../../scss/youtube-lp';

    .separator {
        position: relative;
        height: 20vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
    }

    .separator.shaped {
        
        .top::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 50px;
            width: 100%;
            left: 20%;
            background: chocolate;
            z-index: 0;
        }

        .bottom::after {
            content: '';
            position: absolute;
            top: 0;
            height: 50px;
            width: 100%;
            right: 20%;
            background: chocolate;
            z-index: 2;
        }
    }

    .separator.shaped2 {
        .top::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 50px;
            width: 50%;
            left: -20%;
            background: chocolate;
            z-index: 0;
            @include skew(-5deg, 0deg);
        }

        .bottom::before {
            content: '';
            position: absolute;
            top: -50px;
            height: 50px;
            width: 40%;
            right: -20%;
            background: $white;
            @include skew(-5deg, 0deg);
            border: #ccc solid 1px;
        }

        .bottom::after {
            content: '';
            position: absolute;
            top: 0;
            height: 50px;
            width: 50%;
            right: -20%;
            background: $gold;
            z-index: 2;
            @include skew(-5deg, 0deg);
        }
    }

    .separator-inner {
        position: relative;
        height: inherit;
        width: 120%;
    }

    .top {
        position: absolute;
        top: -50%;
        left: -10%;
        height: inherit;
        width: inherit;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .bottom {
        position: absolute;
        bottom: -50%;
        left: -10%;
        height: inherit;
        width: inherit;
    }



    .circled {

        .shape-left {
            @extend .shape-1;
            bottom: -14%;
            top: auto;
        }

        .shape-right {
            @extend .shape-2;
            top:-60vh;
        }

        .top {
            z-index: 3;
        }
        
        .shape-left, .shape-right {
            z-index: 2;
        }

        .bottom {
            z-index: 1;
        }
    }

    .circled.inverse {

        .shape-left {
            @extend .shape-1;
            bottom: -14%;
            top: auto;
        }

        .shape-right {
            @extend .shape-2;
            top:-50%;
            top: -15vh;
        }

        .top {
            z-index: 1;
        }
        
        .shape-left, .shape-right {
            z-index: 2;
        }

        .bottom {
            z-index: 3;
        }
    }
@media (max-width: 1024px) {
    .separator {
        position: relative;
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
    }
    
    
    .separator-inner {
        width: 140%;
        height: 50px;
    }

    .bottom {
        left: -18%;
        height: 100px;
        bottom: -50px;
    }

    .top {
        left: -18%;
        height: 100px;
        top: -50px;
    }

    .circled .shape-left {
        bottom: -80%;
    }

    .circled .shape-right {
        top: -84vh;
    }

    .separator.shaped .bottom::after {
        right: 30%;
        height: 30px;
    }

    .separator.shaped .top::after {
        height: 80px;
    }
    
    .separator.shaped2 .top::after {
        height: 80px;
    }

    .separator.shaped2 .bottom::after {
        height: 30px;
    }
    .separator.shaped2 .bottom::before {
        height: 30px;
        top: -30px;
    }
}
@media (max-width: 576px) {
    .separator {
        position: relative;
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
    }
    
    
    .separator-inner {
        width: 140%;
        height: 50px;
    }

    .bottom {
        left: -18%;
        height: 100px;
        bottom: -50px;
    }

    .top {
        left: -18%;
        height: 100px;
        top: -50px;
    }

    .circled .shape-left {
        bottom: -80%;
    }

    .separator.shaped .bottom::after {
        right: 30%;
        height: 30px;
    }

    .separator.shaped .top::after {
        height: 80px;
    }
    
    .separator.shaped2 .top::after {
        height: 80px;
    }

    .separator.shaped2 .bottom::after {
        height: 30px;
    }
    .separator.shaped2 .bottom::before {
        height: 30px;
        top: -30px;
    }
}
</style>