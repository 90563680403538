<template>
    <section class="offering-section" :class="{ 'ja' : $i18n.locale == 'ja' }">
        <separator class="separator-top" :circle="true" :circleRight="true" :bottomColor="'#1a1b1b'" :topColor="'#F5F6FC'" />
        <h2 class="title">
            <span class="main-title">{{ $t('youtubeLong.offering.title.row1') }}</span>
            <span class="main-title">{{ $t('youtubeLong.offering.title.row2') }}</span>    
        </h2>
        <div class="inner-with offering-inner">
            <div class="content-block">
                <h3 class="offering-title">
                    <span>{{ $t('youtubeLong.offering.titleText') }}</span>
                    <span>{{ $t('youtubeLong.offering.titleText2') }}</span>
                </h3>
                <p>{{ $t('youtubeLong.offering.text.row1') }}</p>
                <p>{{ $t('youtubeLong.offering.text.row2') }}</p>
                <p>{{ $t('youtubeLong.offering.text.row3') }}</p>
            </div>
            <div class="image-block">
                <img src="/images/youtube-promo-long/Streaming-Promotion-Section4-min.png" :alt="$t('youtubeLong.offering.titleText')">
            </div>
        </div>
    </section>
</template>
<script>
import separator from '../../components/youtube-lp/separator';

export default {
    components: {
        separator
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import '../../scss/youtube-lp';

.offering-section {
    position: relative;
    background:$black-dark;
    padding: 25vh 0 10vh;
    padding: 31vh 0 8vh;
    overflow: hidden;
}

.offering-inner {
    display: flex;
    position: relative;
    z-index: 1;
}

.content-block {
    width: 50%;

    p {
        padding-top: 15px;
        color:$text-grey;
        @extend .text-font;
    }
}

.image-block {
    position: relative;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    img {
        position: relative;
        top: -40px;
        width: 70%;
    }
}

.title {
    position: relative;
    padding-bottom: 10vh;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.offering-title {
    padding-top: 10vh;
    padding-bottom: 1vh;
    color: #fff;

    @extend .middle-title;

    span {
        display: block;
    }
}

.separator-top {
    position: absolute;
    top:0;
    left: 0;
    right:0;
}

.separator-bottom {
    position: absolute;
    bottom:0;
    left: 0;
    right:0;
}

@media (max-width: 1024px) {
    .offering-section {
        padding: 20vh 0 0vh;
    }
    
    .title {
        position: relative;
        padding-bottom: 8vh;
    }

    .offering-title {
        padding-top: 0;
        padding-bottom: 1vh;
    }
}

@media (max-width: 576px) {
    .offering-inner {
        flex-direction: column;
    }

    .offering-section {
        padding: 20vh 0 0vh;
        height: 1130px;
    }

    .content-block {
        width: 100%;
        p {
            @include font-size(16px);
        }
    }

    .ja .content-block {
        p {
            @include font-size(16px);
        }
    }

    .image-block {
        width: 100%;
        padding-top: 10vh;

        img {
            width: 100%;
        }
    }
    
    .title {
        display: block;
        text-align: center;
    }

    .title span {
        display: inline;
    }

    .ja .title .main-title {
        display: block;
    }
}
</style>