<template>
    <section id="intro" class="intro-section" :class="{ 'ja' : $i18n.locale == 'ja' }">
        <div class="intro-wrapper inner-with">
            <h2 class="title main-title">
                <span>{{ $t('youtubeLong.intro.title') }} <div>{{ $t('youtubeLong.intro.title2') }}</div></span>
            </h2>
            <p class="text text-font">{{ $t('youtubeLong.intro.text') }}</p>
            <div @mouseleave="onLeave()" class="strengths-wrapper">
                <div @mouseover="onOver(1)" class="strength-item">
                    <meteor :isActive="active == 1" class="shape" imgClass="first-img" src="/images/youtube-promo-long/dlf.pt-hand-with-money-png-1687102.svg" :alt="$t('youtubeLong.intro.strengths.strength1.title')"/>
                    <h3 class="small-title">{{ $t('youtubeLong.intro.strengths.strength1.title') }}</h3>
                    <p class="text-font">{{ $t('youtubeLong.intro.strengths.strength1.text') }}</p>
                </div>
                <div @mouseover="onOver(3)" class="strength-item">
                    <meteor :isActive="active == 3" class="shape" imgClass="third-img" src="/images/youtube-promo-long/52807-200.svg" :alt="$t('youtubeLong.intro.strengths.strength3.title')"/>
                    <h3 class="small-title">{{ $t('youtubeLong.intro.strengths.strength3.title') }}</h3>
                    <p class="text-font">{{ $t('youtubeLong.intro.strengths.strength3.text') }}</p>
                </div>
                <div @mouseover="onOver(2)" class="strength-item">
                    <meteor :isActive="active == 2" class="shape" imgClass="second-img" src="/images/youtube-promo-long/1221123-200.svg" :alt="$t('youtubeLong.intro.strengths.strength2.title')"/>
                    <h3 class="small-title">{{ $t('youtubeLong.intro.strengths.strength2.title') }}</h3>
                    <p class="text-font">{{ $t('youtubeLong.intro.strengths.strength2.text') }}</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import meteor from '../../components/youtube-lp/icon-meteor';
export default {
    data() {
        return {
            active: 0
        }
    },
    components: {
        meteor
    },
    methods: {
        onOver(id){ 
            this.active = id;
        },
        onLeave() {
            this.active = 0;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import '../../scss/youtube-lp';
.intro-section {
    overflow: hidden;
    position: relative;
    background: $white;
    padding: 10vh 0;
}


.title {
    padding: 2vw 0 0;
    span {
        @extend .title-underline;
    }

    div {
        display: inline;
    }
}

.text {
    position: relative;
    text-align: center;
    margin-top: 3vh;
    font-size: 1.7vw;
    padding: 4vh 7vw 10vh;
}

.strengths-wrapper {
    display: flex;
    justify-content: space-between;
}

.strength-item {
    width: 30%;
    padding: 10px;

    h3 {
        position: relative;
        padding-top: 5vh;
        padding-bottom: 20px;
        margin-bottom: 10px;
        text-align: center;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 35%;
            height: 2px;
            width: 30%;
            background: red;
        }
    }

    p {
        padding-top: 20px;
        text-align: justify;
    }
}

.shape {
    height: 200px;
}

@media (max-width: 1024px) {
    .intro-section {
        padding: 0 0 5vh;
    }

    .text {
        font-size: 3vw;
        padding: 5vh 5vw 5vh;
        margin-top: 0;
    }

    .strength-item {
        width: 32%;
    }
}

@media (max-width: 576px) {
    .intro-section {
        height: 1640px;
    }
    .title.main-title {
        padding: 2vw 0 0;
        span {
            display: block;
        }

        div {
            display: block;
        }
    }

    .ja  .title.main-title {
        font-size: 7vw;
    }

    .intro-section {
        padding: 5vh 0 10vh;
    }

    .text {
        text-align: justify;
        font-size: 6vw;
        padding: 5vh 0 10vh;
        @include font-size(19px);
    }

    .ja .text {
        @include font-size(19px);
    }

    .strengths-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .strength-item {
        width: 100%;
        padding: 10px 0;
        h3 {
            padding-top: 0;
        }
    }

    .ja .strength-item p {
        @include font-size(16px);
    } 
}

</style>