<template>
    <div class="wrapper" style="overflow:hidden">
        <cookies-popup />
        <navigation/>
        <headerSection/>
        <packages id="packages" />
        <intro/>

        <offering/>
        <testimonials/>
        <intro3/>
        <services/>
        <!-- new testimonials -->
        <faq id="faq" />
        <!-- <mapOffering/> -->

        
        <!-- <stages/> -->
        
        <!-- <intro2/>
        <pricing/>
        <servicesDetail id="services" /> -->
        

    

        <!-- <bottomAction/> -->
        <foot/>
    </div>
</template>
<script>
import navigation from '../sections/youtube-lp-long/nav';
import headerSection from '../sections/youtube-lp-long/header';
import intro from '../sections/youtube-lp-long/intro';
// import intro2 from '../sections/youtube-lp-long/intro2';
import intro3 from '../sections/youtube-lp-long/intro3';
import offering from '../sections/youtube-lp-long/offering';
// import mapOffering from '../sections/youtube-lp-long/map';
// import pricing from '../sections/youtube-lp-long/pricing';
import services from '../sections/youtube-lp-long/services';
import testimonials from '../sections/youtube-lp-long/testimonials';
//import servicesDetail from '../sections/youtube-lp-long/services-detail';
// import stages from '../sections/youtube-lp-long/stages';
import packages from "../sections/youtube-lp-long/packages";
import faq from "../sections/youtube-lp-long/faq";
import foot from "./../sections/footer-small";
import cookiesPopup from './../components/cookies-popup'
// import bottomAction from './../sections/youtube-lp-long/actionBottom.vue';


export default {
    metaInfo: {
        title: "D&D Promotion - YouTube Promotion",
    },
    components: {
        navigation,
        headerSection,
        intro,
        // intro2,
        intro3,
        offering,
        // mapOffering,
        // pricing,
         services,
        testimonials,
        //servicesDetail,
        // stages,
        packages,
        faq,
        foot,
        cookiesPopup,
        // bottomAction
    },
    mounted() {
        document.body.style.overflowY = "auto";
        var parser = document.createElement('a');
        parser.href = window.location.href;

        if(parser.hash) {
            var element = document.querySelector(parser.hash);
            setTimeout(() => window.scroll(0, element.offsetTop), 1600);
        }
    }
}
</script>