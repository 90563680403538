<template>
    <section class="faq-section" :class="{ 'ja' : $i18n.locale == 'ja' }">
        <separator class="top-separator" :bottomColor="'rgb(18, 19, 19)'" :topColor="'#F5F6FC'" />
        <div class="inner-with faq-body">
            <h2 class="main-title">{{ $t('youtubeLong.faq.title') }}</h2>
            <div class="subtitle-text">
                <p>
                    {{ $t('youtubeLong.faq.subtitle') }}
                    <router-link to="/contacts">{{ $t('youtubeLong.faq.link') }}</router-link>
                    {{ $t('youtubeLong.faq.subtitle2')  }}
                </p>
            </div>
            
            <div v-for="(item, i) in questions" :key="item.id" @click="activeIndex = activeIndex == i ? null : i" class="qust-container" :class="{ active: activeIndex  == i, none: item.lang != $i18n.locale}">
                 <h3 class="qust-title small-title">{{ item.question  }} <svg viewBox="0 0 24 24" fill="#ccc"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.47 8.845a.75.75 0 011.06 0l5.47 5.47 5.47-5.47a.75.75 0 111.06 1.06l-6 6a.75.75 0 01-1.06 0l-6-6a.75.75 0 010-1.06z" fill="currentColor"></path></svg></h3>
                 <div class="qust-body">
                     <p class="text-font" v-html="item.answer"></p>
                 </div>
            </div> 
            <callAction />
            <div class="header-bg">
                <div class="shape-1"></div>
            </div>
        </div>
    </section>
</template>
<script>
import separator from '../../components/youtube-lp/separator';
import callAction from '../../components/youtube-lp/calltoaction.vue';

export default {
    data() {
        return {
            activeIndex: null,
            packagePrice: 0,
            questions: []
        }
    },
    components: {
        separator,
        callAction
    },
    mounted() {
        this.$http({ url: 'packages/slug/minimum', method: 'GET'})
            .then((resp) => {
                this.packagePrice = +resp.data.data.price;
            });

        this.$http({ url: 'faq', method: 'GET'}).then((resp) => {
            this.questions = resp.data.data;
        });
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import '../../scss/youtube-lp';

.faq-section {
    position: relative;
    background: $black;
    min-height: 120vh;
    padding-top: 35vh;
    padding-bottom: 30vh;

    h2 {
        @extend .main-header-white;
        padding-top: 0;
    }

    .header-bg {
        bottom:0vh;
        .shape-1 {
            bottom: -59vh;
            top: auto;
        }
    }
}

.top-separator {
    position: absolute;
    top:0;
    width: 100%;
    overflow: hidden;
}

.subtitle-text {
    position: relative;
    margin-top: 3vh;
    padding: 4vh 10vw 15vh;

    p {
        text-align: center;
        @extend .text-font;
        color: $text-grey;
    }

    a {
        @extend .text-link;
        color: #939999;
        font-weight: 700;
    }
}

.qust-container {
    position:relative;
    padding: 24px;
    border-radius: 4px;
    background-color: #191c21;
    margin-bottom: 15px;
    cursor:pointer;
    padding-right: 18%;

    &:hover {
        background: #272c33;
    }

    &.active {
        background: #272c33;
        
        .qust-title  svg{
            @include rotate(180deg);
        }

        .qust-body {
            display: block;
        }
    }
}

.qust-title {
    color:$white;
    font-family: $family-bold;
    font-size: 1.5vw;

    svg {
        position: absolute;
        right:24px;
        top: 24px;
        width: 24px;
        height: 24px;

        @include rotate(0);
    }
}

.qust-body {
    display: none;
}

.qust-body p {
    padding-top: 15px;
    font-family: $family-light;
    color: #ccc;
    @include font-size(16px);
}


@media (max-width: 1024px) {
    
    .faq-section {
        min-height: auto;
        padding-top: 20vh;
        padding-bottom: 10vh;

        .header-bg {
            bottom:0vh;
            .shape-1 {
                bottom: -90vh;
                top: auto;
            }
        }
    }

    .subtitle-text {
        padding: 4vh 0 5vh;
    }

    .faq-body {
        height: 100%;
    }


}

@media (max-width: 576px) {
    .faq-section {
        min-height: 1200px;
    }
    
    .qust-container {
        h3 {
            padding-right: 20px;
        }
    }

    .qust-title {
        @include font-size(21px);
    }

    .qust-title {
        @include font-size(21px);
    }

    .ja .subtitle-text p{
        @include font-size(16px);
    }
}

.none {
    display:none;
}
</style>