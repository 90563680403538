<template>
  <div v-if="video" class="collage-container">
    <div class="header-carousel">
      <div class="carousel-header">
        <div class="disabled-menu">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="logo">
          <img src="/images/youtube-promo-long/logo-youtube.png" alt="YouTube Logo" />
        </div>
      </div>
      <div class="view-port">
        <div class="video-wrapper">
            <iframe :src="'https://www.youtube.com/embed/' + video.youtube_id + '?autoplay=1&amp;loop=1&amp;playlist=' + video.youtube_id + '&controls=0&iv_load_policy=3&mute=1&rel=0'" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="video-caption">
          <p><b>{{ video.title }}</b></p>
          <p><countDown class="count-down" :from="1000000" :to="9999999" :duration="4500"/> views <span class="dot-title-video"></span> {{ day }} {{ month }} {{ year }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import countDown from './../animated-number';
export default {
    data() {
        return {
            video: null,
            day: null,
            month: null,
            year: null,
            months: {
                "01": "Jan.",
                "02": "Feb.",
                "03": "Mar.",
                "04": "Apr.",
                "05": "May",
                "06": "Jun.",
                "07": "Jul.",
                "08": "Aug.",
                "09": "Sept.",
                "10": "Oct.",
                "11": "Nov.",
                "12": "Dec."
            },
            showItemDuration: 7000,
            showCurIndex: null,
            showCurComments: [],
            showComment: null,
            showCommentInterval: 1000,
            showData: [
                {
                    gif: "/images/youtube-promo-long/logan-paul.gif",
                    name: "Logan Paul"
                },
                {
                    gif: "/images/youtube-promo-long/markiplier.gif",
                    name: "Markiplier"
                }
            ]
        }
    },
    methods: {
        startShow() {
           this.showCurIndex  = !this.showData[this.showCurIndex + 1] ? 0 : this.showCurIndex + 1;
           this.showCurComments = [];
        },
    },
    mounted() { 

        this.$http({ url: 'video-show', method: 'GET'})
            .then(resp => this.video =  resp.data.videos[Math.floor(Math.random() * resp.data.videos.length)]);
        
        setInterval(this.startShow, this.showItemDuration);
        const today = new Date();
        this.day = String(today.getDate()).padStart(2, '0');
        this.month = this.months[String(today.getMonth() + 1).padStart(2, '0')];
        this.year = today.getFullYear();
    },
    components: {
        countDown
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";

.collage-container {
    position: relative;
    background: #F9F9F9;
    border-radius: 30px;
}

.carousel-header {
    position: relative;
    display: flex;
    height: 56px;
    width: 100%;
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
    overflow: hidden;
}

.disabled-menu {
    padding:0 8px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 24px;
    height: 24px;
    span {
        display: block;
        width: 18px;
        height: 2px;
        background: #606060;
        margin: 1.5px 0;
    }
}

.logo {
    position: relative;
    img {
        position: relative;
        top: -10px;
        width: auto;
        height: 44px;
    }
}

.view-port {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    position: relative;
    
    .video-wrapper {
        position: relative;
        overflow: hidden;
        height: 300px;
        &::after {
            content: '';
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent;
            z-index: 1;
        }
    }
    
    iframe {
        position: relative;
        left: 50%;
        @include translate(-50%, -70px);
        width: 800px;
        height: 400px;
    }
}


.video-caption {
    position: relative;
    top: -19px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    p {
        &:first-child {
            color: #030303;
            font-size: 21px;
        }
        display: flex;
        width: 100%;
        color: #909090;
        font-family: Helvetica, sans-serif;
    }
}

.count-down {
    display: block;
    width: 76px;
    color: #909090;
    font-family: Helvetica, sans-serif;
}

.dot-title-video {
    height: 4px;
    width:4px;
    border-radius: 50%;
    background: #909090;
    margin: 10px 7px 0;
    font-family: Helvetica, sans-serif;
}
</style>