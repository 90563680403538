<template>
    <div class="meteor-container">
        <div class="bg" :class="{ 'active' : isActive }">
            <div class="shape1"></div>
            <div class="shape2"></div>
        </div>
        <div class="content">
            <img :class="imgClass" :src="src" :alt="alt">
        </div>
    </div>
</template>
<script>
export default {
    props: {
        src: {
            default: "",
            type: String
        },
        alt: {
            default: "",
            type: String
        },
        imgClass: {
            default: "",
            type: String
        },
        isActive: {
            default: false,
            type: Boolean
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import "../../scss/youtube-lp";

.meteor-container {
    position: relative;
    height: inherit;
    width: 100%;
}

.bg {
    position: absolute;
    width: 200px;
    height: 200px;
    margin-left:-100px;
    margin-top: -100px;
    top: 50%;
    left: 50%;
    border: 1px solid #ccc;
    border-radius: 50%;
    @include animation(rotating 4s linear infinite);
    @include animation-play-state(paused);

    &.active {
        @include animation-play-state(running);
    }
}

.shape1, .shape2 {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $gold;
}

.shape1 {
    top:20px;
    left: 20px;
}

.shape2 {
    bottom:20px;
    right: 20px;
}

.content {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    img {
        position: relative;
        max-width: 100px;
        max-height: 100px;
    }
}

.first-img {
    top: -10px;
    left: -5px;
}

.second-img {
    top: -10px;
}

.third-img {
    top: 5px;
}


@include keyframes(rotating) {
  from {
    @include rotate(0deg);
  }
  to {
    @include rotate(360deg);
  }
}


@media (max-width: 1024px) {
    .bg {
        width: 150px;
        height: 150px;
        margin-left:-75px;
        margin-top: -75px;
    }

    .content img {
        max-width: 75px;
        max-height: 75px;
    }

    .shape1, .shape2 {
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $gold;
    }

    
    .shape1 {
        top:15px;
        left: 15px;
    }

    .shape2 {
        bottom:15px;
        right:15px;
    }
}


</style>