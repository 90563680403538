var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"separator",class:_vm.shapeClass},[_c('div',{staticClass:"separator-inner",class:{ 'circled': _vm.circle, 'inverse': _vm.isBottom }},[_c('div',{staticClass:"top",style:({
            '-webkit-transform': 'rotate('+ _vm.degrees * _vm.invertDegris + 'deg)',
            '-moz-transform': 'rotate('+ _vm.degrees * _vm.invertDegris + 'deg)',
            '-ms-transform': 'rotate('+ _vm.degrees * _vm.invertDegris  + 'deg)',
            '-o-transform': 'rotate('+ _vm.degrees * _vm.invertDegris  + 'deg)',
            'transform': 'rotate('+ _vm.degrees * _vm.invertDegris + 'deg)',
            'background': _vm.topColor
        })}),_c('div',{staticClass:"bottom",style:({
            '-webkit-transform': 'rotate('+ _vm.degrees * _vm.invertDegris + 'deg)',
            '-moz-transform': 'rotate('+ _vm.degrees * _vm.invertDegris  + 'deg)',
            '-ms-transform': 'rotate('+ _vm.degrees * _vm.invertDegris  + 'deg)',
            '-o-transform': 'rotate('+ _vm.degrees * _vm.invertDegris  + 'deg)',
            'transform': 'rotate('+ _vm.degrees * _vm.invertDegris  + 'deg)',
            'background': _vm.bottomColor
        })}),(_vm.circle)?_c('div',{class:{'shape-right': _vm.circleRight, 'shape-left': _vm.circleLeft && !_vm.circleRight }}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }